import { IRegionDetail } from '../store/region/types';
import { isUserAdmin } from '../utils/commonUtils';

export const PROTECTED_ROUTES = {
  regionDashboard: '/region',
  site: '/region/:regionId/:regionName/site',
  siteByRegion: '/region/:regionId/:regionName',
  reportList: '/region/:regionId/:regionName/site/:siteId/:siteName',
  HFReport: '/region/:regionId/:regionName/site/:siteId/:siteName/:reportType',
  manageUserRoles: '/manage-user-roles',
  home: '/home',
  quickSightDashboard: '/dashboard',
  spiceQuickSightDashboard: '/dashboard/spice-quicksight',
  tcQuickSightDashboard: '/dashboard/telecounselling-quicksight'
};

export const getHomeUrlByRole = (role: string, regionData: IRegionDetail) => {
  const { id: regionId, name: regionName } = regionData;
  const currentURL = isUserAdmin(role)
    ? PROTECTED_ROUTES.regionDashboard
    : PROTECTED_ROUTES.siteByRegion.replace(':regionId', String(regionId)).replace(':regionName', regionName);
  return currentURL;
};
