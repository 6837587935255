import styles from './ReportList.module.scss';
import { useMemo } from 'react';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useParams, Link } from 'react-router-dom';
import sessionStorageServices from '../../global/sessionStorageServices';
import APPCONSTANTS from '../../constants/appConstants';
import { useSelector } from 'react-redux';
import { isHF4UserSelector } from '../../store/user/selectors';

interface IMatchParams {
  regionId: string;
  regionName: string;
  siteId: string;
  siteName: string;
}

const ReportList = (props: IMatchParams): React.ReactElement => {
  const { siteId, siteName, regionId, regionName } = useParams<IMatchParams>();
  const countryId = sessionStorageServices.getItem(APPCONSTANTS.REGION_ID);

  const isHF4User = useSelector(isHF4UserSelector);

  const reports = useMemo(
    () => [
      {
        name: 'HF',
        label: 'HF1 Report',
        hasAccess: !isHF4User
      },
      {
        name: 'HF',
        label: 'HF2 Report',
        hasAccess: !isHF4User
      },
      {
        name: 'HF',
        label: 'HF3 Report',
        hasAccess: !isHF4User
      },
      {
        name: 'HF',
        label: 'HF4 Report',
        hasAccess: true
      }
    ],
    [isHF4User]
  );

  const parsedData = useMemo(
    () =>
      reports
        .filter(({ hasAccess }) => hasAccess)
        .map(({ label, name }) => ({
          title: label,
          detailRoute: PROTECTED_ROUTES.HFReport.replace(':regionId', regionId)
            .replace(':regionName', regionName)
            .replace(':siteId', siteId)
            .replace(':siteName', siteName)
            .replace(':reportType', label.slice(0, 3).toLowerCase() + '-' + label.slice(4)),
          _id: countryId,
          icon: require(`../../assets/images/${name}.svg`).default,
          name: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME)
        })),
    [regionId, regionName, reports, siteId, siteName, countryId]
  );

  return (
    <div className={`position-relative ${styles.regionContainer}`}>
      <div className='row'>
        {parsedData.map((data) => (
          <div className='col-3 p-1 width-20' key={data.title}>
            <div className={styles.cards}>
              <Link to={data.detailRoute}>
                <div className='row p-2'>
                  <img src={data.icon} alt={`${data.title}_logo`} width={60} height={70} />
                </div>
                <div className={`row ${styles.report_card_text} py-1`}>
                  <p>{data.title.toUpperCase()}</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportList;
