import axios from 'axios';
import { saveAs } from 'file-saver';

export const fileDownload = async (siteName: string, type: string, reportDate: any, reportData: []) => {
  const fileName = siteName + type + reportDate;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const blob = new Blob([new Uint8Array(reportData)], { type: fileType });
  return saveAs(blob, fileName + fileExtension, { autoBom: false });
};

export const fetchReportList = (data: {
  key: string;
  reportType: string;
  healthFacilityId: number;
  limit: number;
  userBased: boolean;
  userId: number | undefined;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/list',
    data
  });

export const fetchHFReport = (data: {
  month: string;
  year: string;
  healthFacilityId: number | undefined;
  userId?: number;
  userBased: boolean;
  reportType: string;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/hf-report',
    data
  });
