import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap';

import './index.scss';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { setupInterceptors } from './global/interceptors';
import sessionStorageService from './global/sessionStorageServices';
import APPCONSTANTS from './constants/appConstants';
import { setSiteDetail } from './store/site/actions';

const siteId = sessionStorageService.getItem(APPCONSTANTS.SITE_ID);
const siteTenantId = sessionStorageService.getItem(APPCONSTANTS.SITE_TENANT_ID);
const chwUserId = sessionStorageService.getItem(APPCONSTANTS.CHW_USER_ID);

if (siteId || siteTenantId || chwUserId) {
  const siteDetails = {
    ...(siteId && { id: siteId }),
    ...(siteTenantId && { tenantId: siteTenantId }),
    ...(chwUserId && { userId: chwUserId })
  };
  store.dispatch(setSiteDetail(siteDetails));
}

setupInterceptors(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
